<template>
  <div class="maxdiv">
    <!--语言选择-->
    <!--<span class="lang-select" style="float: right">-->
    <span class="lang-select">
            <el-select v-model="lang.value" @change="clang">
                <el-option
                  v-for="item in lang.options"
                  :key="item.value"
                  :label="item.label"
                  style="z-index: 10000001"
                  :value="item.value">
                </el-option>
            </el-select>
          </span>
    <div class="ICPdiv">
      <p>
        Copyright ©
        <!--<ta-icon name="copyright" />-->
        {{ new Date().getFullYear() }} 版权所有 巨紫（深圳）网络科技有限公司
      </p>
      <p class="">
        <a href="http://beian.miit.gov.cn/" target="_blank"
        >ICP备案号：粤ICP备18119357号-1</a
        >
      </p>
    </div>
    <div class="right_div">
      <div class="title_div">
        <div class="name_div">
          {{ $t("Juzi") }}
        </div>
        <div class="nbsp"></div>
        <div class="title_info">
          <h2>SaaS后台信息管理系统</h2>
          <span>智汇律神·商会版</span>
        </div>
      </div>
      <!--登陆界面-->
      <div class="userform" v-show="show">
        <el-form
          :model="formLogin"
          :rules="rules"
          ref="formLogin"
          label-width="10px"
          class="demo-ruleForm"
          v-show="show"
        >
          <el-form-item prop="account">
            <el-input
              v-model="formLogin.account"
              style="width:300px;"
              type="text"
              :placeholder="$t('InputAccount')"
            >
              <i slot="prepend" class="fas user-circle-o" />
            </el-input>
            <el-checkbox v-model="remember" style="margin-left: 20px">{{ $t("Remember") }}</el-checkbox>
          </el-form-item>
          <el-form-item prop="passWord">
            <el-input
              type="password"
              v-model="formLogin.passWord"
              style="width:300px"
              :placeholder="$t('InputPassword')"
            >
              <i slot="prepend" class="fas user-circle-o" />
            </el-input>
          </el-form-item>
          
          <div class="forget_btn">
            <br /><br /><br />
            <!--       <el-button
                         type="text"
                         @click="toQQ"
                         style="margin-left: 10px; color: white;"
                 >
                   在线咨询
                 </el-button>-->
            <!--<el-checkbox v-model="remember">{{$t('Remember')}}</el-checkbox>-->
            <el-button type="text" @click="forgetpwd" style="color: white;margin-right: 100px">
              {{ $t("ForgetPassword") }}
            </el-button>
          </div>
          <el-form-item>
            <el-button
              type="primary"
              style="width: 300px; background-color:#FFCC00; color: black; font-size: 20px; font-weight: bold; letter-spacing: 40px;text-indent: 38px;"
              @click="submitForm('ruleForm')"
            >{{ $t("Login") }}
            </el-button
            >
          </el-form-item>
        </el-form>
        <button @click="clearCacheChamber">清除缓存</button>
        <br>
        <button @click="zyd">巨紫商会</button>
        <br>
        <button @click="jr">浙企出海+</button>
      </div>
      
      <!--忘记密码-->
      <div class="userform1" v-show="!show">
        <h2
          v-show="!show"
          style="color: white; font-size: 30px;letter-spacing: 5px;
        font-weight: 400;"
        >
          {{ $t("ForgetPassword") }}
        </h2>
        <el-form
          :model="forgetFormLogin"
          :rules="rules"
          ref="forgetFormLogin"
          label-width="10px"
          class="demo-ruleForm"
          v-if="!show"
        >
          <el-form-item prop="forgetAccount">
            <el-input
              v-model="forgetFormLogin.forgetAccount"
              style="width:300px;"
              type="text"
              :placeholder="$t('InputAccount')"
            >
              <i slot="prepend" class="fas user-circle-o" />
            </el-input>
          </el-form-item>
          <el-form-item prop="forgetpassWord">
            <el-input
              type="password"
              v-model="forgetFormLogin.forgetpassWord"
              style="width:300px"
              :placeholder="$t('InputPassword')"
            >
              <i slot="prepend" class="fas user-circle-o" />
            </el-input>
          </el-form-item>
          <el-form-item prop="forgetpassWordConfirm">
            <el-input
              type="password"
              v-model="forgetFormLogin.forgetpassWordConfirm"
              style="width:300px"
              :placeholder="$t('ConfirmPassword')"
            >
              <i slot="prepend" class="fas user-circle-o" />
            </el-input>
          </el-form-item>
          <el-form-item prop="forgetvalidateCode">
            <el-input
              type="password"
              v-model="forgetFormLogin.forgetvalidateCode"
              style="width:190px"
              :placeholder="$t('InputValidCode')"
            >
              <i slot="prepend" class="fas user-circle-o" />
            </el-input>
            <el-button
              v-if="!codeshow"
              size="default"
              @click="verCode(forgetFormLogin.forgetphone)"
              type="primary"
            >
              {{ $t("GetValidCode") }}
            </el-button>
            <el-button
              v-if="codeshow"
              size="default"
              disabled
              style="width:112px;"
              type="primary"
            >
              {{ auth_time }}{{ $t("Second") }}
            </el-button>
          </el-form-item>
          <el-form-item>
            <el-button
              type="primary"
              style="width: 300px; font-size: 20px; font-weight: bold; letter-spacing: 40px;text-indent: 38px;"
              @click="submitForm('ruleForm')"
            >{{ $t("Confirm") }}
            </el-button
            >
          </el-form-item>
          <el-button
            type="text"
            @click="fanhui"
            style="margin-left: 10px; color: white;"
          >
            &nbsp;{{ $t("BackLogin") }}
          </el-button>
        </el-form>
      </div>
    </div>
  </div>
</template>
<script>
import api from "@/fetch/api";
import { mapMutations } from "vuex";
import moment from "moment";

export default {
  inject: ["reload"],
  data() {
    const validatePhone = (rule, value, callback) => {
      let regTest = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!value) {
        callback(new Error("手机号码不能为空"));
      } else {
        if (!regTest.test(value)) {
          callback(new Error("手机号码格式不正确"));
          return;
        }
        callback();
      }
    };
    const validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("InputPassword")));
      } else {
        if (this.forgetFormLogin.forgetpassWordConfirm !== "") {
          this.$refs.regisForm.validateField("forgetpassWordConfirm");
        }
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t("InputPassword2")));
      } else if (value !== this.forgetFormLogin.forgetpassWord) {
        callback(new Error(this.$t("ErrorPassword2")));
      } else {
        callback();
      }
    };
    return {
      show: true,  // true: 登录   false:忘记密码
      codeshow: false,
      auth_time: 30,
      remember: false,
      cache: false,
      threeMonths: 7776000000,
      userRouter: [],
//      canLogin:'',
      
      formLogin: {
        account: "",
        passWord: ""
      },
      forgetFormLogin: {
        forgetAccount: "",
        forgetpassWord: "",
        forgetpassWordConfirm: "",
        forgetvalidateCode: ""
      },
//        语言变化
      lang: {
        options: [{
          value: "zh-CN",
          label: "简体中文"
        }, {
          value: "en-US",
          label: "English"
        }, {
          value: "bahasa",
          label: "Bahasa"
        }],
        value: sessionStorage.locale ? sessionStorage.locale : "zh-CN"
      },
      rules: {
        account: [{ required: true, message: this.$t("InputAccount"), trigger: "blur" }],
        passWord: [{ required: true, message: this.$t("InputPassword"), trigger: "blur" }],
        forgetphone: [
          { required: true, validator: validatePhone, trigger: "blur" }
        ],
        forgetpassWord: [
          { required: true, validator: validatePass, trigger: "blur" }
        ],
        forgetpassWordConfirm: [
          { required: true, validator: validatePass2, trigger: "blur" }
        ]
      }
    };
  },
  created() {
//从sessionStorage中读取倒计时相关信息
    if (sessionStorage.getItem("CountDownInfo")) {
      this.auth_time = JSON.parse(
        sessionStorage.getItem("CountDownInfo")
      ).countDown;
      this.codeshow = JSON.parse(
        sessionStorage.getItem("CountDownInfo")
      ).button;
    }
//进入该页面时，若倒计时还在进行，则继续跑倒计时
    if (this.codeshow) {
      let timerId = setInterval(() => {
        this.auth_time--;
        if (this.auth_time <= 0) {
          clearInterval(timerId);
          this.auth_time = 30;
          this.codeshow = false;
        }
      }, 1000);
    }
//刷新时将倒计时保存到sessionStorage里
    window.addEventListener("beforeunload", () => {
      sessionStorage.setItem(
        "CountDownInfo",
        JSON.stringify({
          countDown: this.auth_time,
          button: this.codeshow
        })
      );
    });
  },
  mounted() {
//        若有缓存，读取缓存
    let cache = JSON.parse(localStorage.getItem("cacheChamber")) || JSON.parse(sessionStorage.getItem("cacheChamber"));
    if (cache) {
      if (cache.adminData) {
        this.cache = true;
        this.remember = true;
//                console.log('cacheChamber',cache);
//                console.log('cacheTime',cache.time);
//                console.log('today',moment().valueOf());
//          三个月内自动填写登录信息
        if ((moment().valueOf()) - cache.time < this.threeMonths) {
          this.formLogin.account = cache.adminData.username;
          this.formLogin.passWord = cache.adminData.password;
          return;
        } else {
//              三个月后清除用户登录信息
          localStorage.removeItem("cacheChamber");
          this.cache = false;
        }
      }
    } else {
      this.remember = false;
    }
  },
  methods: {
//      变换语言
    clang: function(value) {
      this.setCookie("org.springframework.web.servlet.i18n.CookieLocaleResolver.LOCALE", value === "zh-CN" ? "zh_cn" : "en");
      sessionStorage.locale = value;
      location.reload();
    },
    
    // 忘记密码点击事件
    forgetpwd() {
      // this.show = false;
      this.$router.push({
        name:'ForgetPassword'
      })
    },

//      发送验证码
    async verCode(tel) {
      //验证手机号是否被注册
      /*let data = await api.isNewAdmin({mobile:tel})
      if(parseInt(data.code)===0){
          alert('当前手机号已被注册')
          return;
      }
      //服务器发送验证码
      data = await api.getCode({mobile:tel});
      if(parseInt(data.code)===1){
          alert('当前网络繁忙，请稍后请求')
          return
      }*/
      //开启倒计时
      this.countDown("codeshow", "auth_time", 30);
      
    },

//      实现更改密码
    handleForget(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //验证码有效性（后端验证）
          /*let validCode = api.checkCode().then(res=>{res.msg})
          if (validCode === 1){
              api.forgetPassword()
              return
          }else{
              window.alert('服务器错误，请稍后重试')
          }*/
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },

//      倒计时   哪个按钮，倒计时变量，初始时间
    countDown(button, countDown, time) {
      this[button] = true;
      let timer = setInterval(() => {
        this[countDown]--;
        console.log("countDown", this[countDown]);
        if (this[countDown] <= 0) {
          this[countDown] = time;
          this[button] = false;
          clearInterval(timer);
          this[countDown] = time;
          return;
        }
      }, 1000);
    },
    
    // 跳转qq
    toQQ() {
      window.open(
        "http://wpa.qq.com/msgrd?v=3&uin=2355342676&site=qq&menu=yes"
      );
    },
    
    // 返回登录页面
    fanhui() {
      this.show = true;
    },

//      清除缓存
    clearCacheChamber() {
      /*localStorage.removeItem('cacheChamber')
      localStorage.removeItem('viewRouters')
      sessionStorage.removeItem('cacheChamber')
      sessionStorage.removeItem('2router')*/
      localStorage.clear();
      sessionStorage.clear();
      this.reload();
    },

//      zyd
    zyd() {
      this.formLogin.account = "ZhangYudanChamberAdmin";
      this.formLogin.passWord = "12345";
    },

//      jr
    jr() {
      this.formLogin.account = "ZheqichuhaiAdmin";
      this.formLogin.passWord = "123456";
    },
    
    //      登录 （原版，以viewRouter的数量判断是否跳转，默认可见：waitingPage & personalCenter）
    /*submitForm() {
          this.$refs.formLogin.validate((valid)=>{
//              后端验证
              if (valid){
                  api.login({
                      account:this.formLogin.account,
                      password:this.formLogin.passWord,
                      osType:2,
                      loginType:1
                  }).then(
                      res =>{
//                            console.log('登录信息',res)
                          if(res.msg === 'accountDisabled'){
                              alert('你的账号已被禁止')
                          }else if(res.msg === 'AccountOrPasswordException'){
                              alert('账号或密码错误')
                          }else if(res.msg === 'accountCancelledContactOperation'){
                              alert('该账号不存在')
                          }else{
//                                如果接口连接成功但是返回null
                              if(res.data === [] || res.data === null){
                                  alert('服务器繁忙，请稍后重试')
                                  console.log('接口传参是否为null',res.data)
                              }else{
                                  this.$store.commit("login/SETUSER",{
                                      adminData:res.data,
                                      time: moment().valueOf()
                                  })
                                  localStorage.setItem('cacheChamber',JSON.stringify({
                                      time: moment().valueOf(),
                                      adminData: res.data
                                  }))

//                                根据账号规定可见路由
                                  let localView = JSON.parse(localStorage.getItem('viewRouters'))
                                  var viewRouters = []
                                  this.$router.options.routes.forEach(item=>{
                                      if(item.meta.view){
                                          viewRouters.push(item)
                                      }
                                  })
//                                把该账户可见的路由存到sessionStorage
                                  window.localStorage.setItem("viewRouters",JSON.stringify({
                                      name:this.formLogin.account,
                                      paths:viewRouters
                                  }))
                                  //不能在push后刷新，当页面不跳转时，应当排查Bug，刷新会导致Bug被隐藏
                                  //不能使用this.$router.go(0)这句代码

                                  if(viewRouters.length === 2){
                                      window.sessionStorage.setItem('2router',2)
                                      //this.$router.go(0)
                                  }else{
//                                        若登录用户与localStorage不同 或 local用户权限被更改
                                      if(localView.name !== this.formLogin.account || sessionStorage.getItem('change')){
                                          window.sessionStorage.setItem('2router',2)
                                          //this.$router.go(0)
                                      }else{
                                          //this.$router.push({ path:viewRouters[0].path})
                                      }
                                  }

                                  console.log('可见路由元',viewRouters)
                                  //苹果电脑跳转要简洁可靠，跳转地址不能用go(0)，不能使用不可预估值的变量
//                                    this.$router.push({ path: "/menu/AllContent" });

                              }
                          }
                      },
                      err =>{
                          console.log('err',err)
                          alert('服务器繁忙，请稍后重试')
                      }
                  )
              }

          })
//        this.$store.dispatch("login", "/home");
//        this.$router.push({ path: "/menu/AllContent" });
     /!* this.$refs[formLogin].validate(valid => {
        if (valid) {
          alert("submit!");
        } else {
          console.log("error submit!!");
          return false;
        }
      });*!/
    },*/
    
    //      登录（新版，以local用户名判断是否跳转，以防用户一个权限没有）
    /*submitForm() {
        this.$refs.formLogin.validate((valid)=>{
            //              后端验证
            if (valid){
                api.login({
                    account:this.formLogin.account,
                    password:this.formLogin.passWord,
                    osType:2,
                    loginType:1
                }).then(
                    res =>{
//                            console.log('登录信息',res)
                        if(res.msg === 'accountDisabled'){
                            alert('你的账号已被禁止')
                        }else if(res.msg === 'AccountOrPasswordException'){
                            alert('账号或密码错误')
                        }else if(res.msg === 'accountCancelledContactOperation'){
                            alert('该账号不存在')
                        }else{
//                                如果接口连接成功但是返回null
                            if(res.data === [] || res.data === null){
                                alert('服务器繁忙，请稍后重试')
                                console.log('接口传参是否为null',res.data)
                            }else{
                                this.$store.commit("login/SETUSER",{
                                    adminData:res.data,
                                    time: moment().valueOf()
                                })
                                /!*localStorage.setItem('cacheChamber',JSON.stringify({
                                    time: moment().valueOf(),
                                    adminData: res.data
                                }))*!/

//                                根据账号规定可见路由
                                let localData = JSON.parse(localStorage.getItem('cacheChamber'))
//                                  是否第一次登录
                                if(!localData){
                                    console.log('无adminData')
//                                      sessionStorage.setItem('first',1)
                                    localStorage.setItem('cacheChamber',JSON.stringify({
                                        time: moment().valueOf(),
                                        adminData: res.data
                                    }))
                                    window.sessionStorage.setItem('2router',2)
//                                      this.reload()
//                                      this.$router.go(0)
                                    location.reload()
//                                      this.submitForm()
                                }else{
//                                      是否是同一人登录或更改过权限
                                    if(localData.adminData.username !== this.formLogin.account || sessionStorage.getItem('change')){
                                        console.log('非本人')
                                        localStorage.setItem('cacheChamber',JSON.stringify({
                                            time: moment().valueOf(),
                                            adminData: res.data
                                        }))
                                        window.sessionStorage.setItem('2router',2)
//                                          this.reload()
//                                          this.$router.go(0)
                                        location.reload()
//                                          this.submitForm()
                                    }/!*else if(sessionStorage.first === 1){
                                        console.log('第一次登录的二次刷新')
                                        window.sessionStorage.setItem('2router',2)
                                        sessionStorage.removeItem('first')
//                                          this.reload()
//                                          this.$router.go(0)
                                        location.reload()
//                                          this.submitForm()
                                    }*!/else{
//                                          根据用户数据设定可见路径
                                        var viewRouters = []
                                        this.$router.options.routes.forEach(item=>{
                                            if(item.meta.view != 0){
                                                viewRouters.push(item)
                                            }
                                        })
                                        console.log('可见路由',viewRouters)
//                                          把该账户可见的路由存到localStorage
                                        window.localStorage.setItem("viewRouters",JSON.stringify({
                                            name:this.formLogin.account,
                                            paths:viewRouters
                                        }))
                                        this.$router.push({ path:viewRouters[0].path})
                                    }
                                }
                            }
                        }
                    },
                    err =>{
                        console.log('err',err)
                        alert('服务器繁忙，请稍后重试')
                    }
                )
            }

        })
        //        this.$store.dispatch("login", "/home");
        //        this.$router.push({ path: "/menu/AllContent" });
        /!* this.$refs[formLogin].validate(valid => {
           if (valid) {
             alert("submit!");
           } else {
             console.log("error submit!!");
             return false;
           }
         });*!/
    },*/
    
    //      登录（新版，不用路由守卫控制登录，手动添加路由）
    submitForm() {
      this.$refs.formLogin.validate((valid) => {
        //              后端验证
        if (valid) {
          api.login({
            account: this.formLogin.account,
            password: this.formLogin.passWord,
            osType: 2,
            loginType: 1
          }).then(
            res => {
//                            console.log('登录信息',res)
              if (res.msg === "accountDisabled") {
                alert("你的账号已被禁止");
              } else if (res.msg === "AccountOrPasswordException") {
                alert("账号或密码错误");
              } else if (res.msg === "accountCancelledContactOperation") {
                alert("该账号不存在");
              } else {
                this.userRouter = [];
//                                如果接口连接成功但是返回null
                if (res.data === [] || res.data === null) {
                  alert("服务器繁忙，请稍后重试");
                  console.log("接口传参是否为null", res.data);
                } else {
                  if (res.data.systemPermission !== 0) {
//                                      console.log('systemPermission',res.data.systemPermission)
                    this.userRouter.push("systemPermission");
                  }
                  if (res.data.messagePermission !== 0) {
//                                      console.log('messagePermission',res.data.messagePermission)
                    this.userRouter.push("messagePermission");
                  }
                  if (res.data.userPermission !== 0) {
//                                      console.log('userPermission',res.data.userPermission)
                    this.userRouter.push("userPermission");
                  }
                  if (res.data.administratorPermission !== 0) {
//                                      console.log('administratorPermission',res.data.administratorPermission)
                    this.userRouter.push("administratorPermission");
                  }
                  /*if (res.data.financePermission !== 0) {
//                                      console.log('financePermission',res.data.financePermission)
                    this.userRouter.push("financePermission");
                  }*/
                  if (res.data.contentPermission !== 0) {
//                                      console.log('contentPermission',res.data.contentPermission)
                    this.userRouter.push("contentPermission");
                  }
                  this.userRouter.push("postPermission");
                  this.userRouter.push("financePermission");
                  this.userRouter.push("reportPermission");
                  console.log("用户登录可见路由", this.userRouter);
                  this.$store.commit("login/SETUSER", {
                    adminData: res.data,
                    time: moment().valueOf()
                  });
                  localStorage.setItem("cacheChamber", JSON.stringify({
                    time: moment().valueOf(),
                    adminData: res.data
                  }));
//                                根据用户数据设定可见路径
                  var viewRouters = [];
                  this.$router.options.routes.forEach(item => {
                    if (item.meta.view === 1 || this.userRouter.includes(item.meta.view)) {
                      viewRouters.push(item);
                    }
                  });
                  console.log("可见路由", viewRouters);
//                                把该账户可见的路由存到localStorage
                  window.localStorage.setItem("viewRouters", JSON.stringify({
                    name: this.formLogin.account,
                    paths: viewRouters
                  }));
  
                  // 获取系统名称
                  api.getSysName({
                    currentAdmin:res.data.id
                  }).then(resp=>{
                    // console.log('res ->',resp)
                    localStorage.setItem('SysName',resp.msg)
                  })
                  
                  this.$router.push({ path: viewRouters[0].path });
                }
              }
            },
            err => {
              console.log("err", err);
              alert("服务器繁忙，请稍后重试");
            }
          );
        }
        
      });
      //        this.$store.dispatch("login", "/home");
      //        this.$router.push({ path: "/menu/AllContent" });
      /* this.$refs[formLogin].validate(valid => {
         if (valid) {
           alert("submit!");
         } else {
           console.log("error submit!!");
           return false;
         }
       });*/
    }
  },
  updated() {
    let router2 = sessionStorage.getItem("2router");
    if (router2) {
      this.submitForm();
      sessionStorage.removeItem("2router");
      if (sessionStorage.getItem("change")) sessionStorage.removeItem("change");
    }
//        console.log('updated')
  },
  
  beforeRouteLeave(to, from, next) {
    let viewRouter = JSON.parse(localStorage.getItem("viewRouters")).paths;
//        console.log('beforeRouteLeave',viewRouter)
//        console.log('beforeRouteLeave',to.path)
    next();
  }
};
</script>
<style lang="scss" scoped>
.maxdiv {
  height: 937px;
  width: 1900px;
  /*height: 100%;*/
  position: relative;
  background: url("./assets/back@1080.jpg") 0 0 no-repeat scroll transparent;
  background-size: cover;
  overflow: hidden;
}

.right_div {
  background-color: rgba(6, 129, 206, 0.78);
  position: absolute;
  top: -200px;
  right: 0;
  bottom: -200px;
  width: 58%;
  border-radius: 70% 0 0 50%;
  
  .title_div {
    position: absolute;
    top: 400px;
    left: 30%;
    height: 80px;
    line-height: 80px;
    color: white;
    
    .name_div {
      display: inline-block;
      font-size: 40px;
      letter-spacing: 10px;
      margin-right: 10px;
    }
    
    .nbsp {
      display: inline-block;
      position: absolute;
      top: 5px;
      height: 70px;
      width: 5px;
      background-color: white;
    }
    
    .title_info {
      display: inline-block;
      letter-spacing: 10px;
      position: absolute;
      width: 400px;
      top: 10px;
      line-height: 30px;
      text-align: center;
      
      h2 {
        font-size: 26px;
        font-weight: 400;
      }
    }
  }
  
  .userform {
    position: absolute;
    top: 600px;
    left: 40%;
  }
  
  .userform1 {
    position: absolute;
    top: 500px;
    left: 40%;
    
    h2 {
      text-align: center;
      margin-bottom: 20px;
    }
  }
}

.forget_btn {
  display: flex;
  justify-content: space-between;
}

.ICPdiv {
  z-index: 999;
  position: absolute;
  left: 45%;
  bottom: 5px;
  color: #606266;
  font-size: 12px;
  
  a {
    text-decoration: none;
    color: #606266;
  }
}
</style>
<style lang="scss">
.userform {
  .el-input__inner {
    background-color: #52a4d7;
  }
}

.userform1 {
  .el-input__inner {
    background-color: #52a4d7;
  }
}

.el-checkbox__label {
  color: white;
}
</style>
